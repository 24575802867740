/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  ApplicationRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { StatusService, UtilDialogService } from '@core';
import {
  // AccountAdminGroup,
  AccountBlanketPurchaseOrder,
  ApprovalTree,
  ProgramType,
  Program,
  ProgramApplicabilityType,
  ProgramStartDateType,
  ProgramStartDateTypeEnum,
  ActiveStatus,
  ShipFrequency,
  ProgramPurchaseOrderType,
  ProgramPcardType,
  AccountProcurementCard,
  ActiveStatusEnum,
  ProgramPcardTypeEnum,
  ProgramUserGroup,
  ShippingUserGroup,
  ProgramPurchaseOrderTypeEnum
} from '@models';
import { DateFns, UtilFns } from '@utils';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import * as _ from 'lodash';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { Entity } from 'breeze-client';

import { AccountDbSaveService } from '../services/account-db-save.service';
import { ToastrService } from 'ngx-toastr';
import { RadioButtonClickEvent } from 'primeng/radiobutton';

@Component({
  selector: 'prox-program-info-sub',
  templateUrl: './program-info.sub.component.html',
})
export class ProgramInfoSubComponent implements OnInit, OnChanges {
  ProgramStartDateTypeEnum = ProgramStartDateTypeEnum;

  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;

  // lookup types
  activeStatuses!: ActiveStatus[];
  programTypes!: ProgramType[];
  programStartDateTypes!: ProgramStartDateType[];
  shipFrequencies!: ShipFrequency[];
  programApplicabilityTypes!: ProgramApplicabilityType[];
  // accountAdminGroups: AccountAdminGroup[] = [];
  approvalTrees!: ApprovalTree[];
  //currentApprovalTreeId = UtilFns.EmptyGuid;
  currentApprovalTreeId = null as any;
  programs!: Program[];
  availableNextPrograms!: Program[];
  isExpired: boolean = false;
  programPurchaseOrderTypes: ProgramPurchaseOrderType[] = [];
  programPcardTypes: ProgramPcardType[] = [];
  pcards!: AccountProcurementCard[];
  blanketPos!: AccountBlanketPurchaseOrder[];
  pcardMessage: string = '';
  poMessage: string = '';

  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService,
    private statusService: StatusService,
    private toastr: ToastrService,
    private appRef: ApplicationRef
  ) {}

  get program() {
    return this.parent.program;
  }

  get manifests() {
    return this.program?.manifests;
  }

  async ngOnInit() {

    const [r0, r1, r2, r3, r4] = await Promise.all([
      this.dbQueryService.getAll(ActiveStatus),
      this.dbQueryService.getAll(ProgramType),
      this.dbQueryService.getAll(ProgramApplicabilityType),
      this.dbQueryService.getAll(ProgramStartDateType),
      this.dbQueryService.getAll(ShipFrequency),
    ]);

    this.activeStatuses = r0;
    this.programTypes = r1;
    this.programApplicabilityTypes = r2;
    this.programStartDateTypes = r3;
    this.shipFrequencies = r4;

    const [p0, p1, p2, p3, p4] = await Promise.all([
      this.dbQueryService.getAccountAdminGroups( this.program.accountId ),
      this.dbQueryService.getApprovalTrees( this.program.accountId ),
      this.dbQueryService.getPrograms( this.program.accountId ),
      this.dbQueryService.getAccountBlanketPurchaseOrders( this.program.accountId ),
      this.dbQueryService.getAccountProcurementCards( this.program.accountId ),
    ]);

    // this.accountAdminGroups = p0;
    this.approvalTrees = p1;
    this.programs = p2;
    this.blanketPos = p3;
    this.pcards = p4;

    this.approvalTrees.unshift({
      id: null as any,
      name: '- None -',
    } as ApprovalTree);

    this.isExpired = this.program.endDate! < (new Date());
    this.currentApprovalTreeId = this.program.approvalTreeId!;

    this.programPurchaseOrderTypes = this.dbQueryService.getAllCached(ProgramPurchaseOrderType);
    this.programPcardTypes = this.dbQueryService.getAllCached(ProgramPcardType);

    this.onPcardChanged({ value: this.program.programPcardTypeId } as any);
    this.onPoChanged({ value: this.program.programPurchaseOrderTypeId } as any);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.parent.updateComponentNameMap(this);
    this.refresh();
  }

  onProgramTypeChange(e) {
    this.parent.buildMainMenu();
  }

  async refresh() {
    //
  }

  async onProgramApplicabilityTypeChanged(programApplicabilityTypeId: string) {
    // do nothing yet
  }

  async onProgramStartDateTypeChanged(programStartDateTypeId: string) {
    // do nothing yet
  }
  
  async onApprovalTreeChanged(approvalTreeId: string) {
    const newAt = this.approvalTrees.find((at) => at.id == approvalTreeId);

    UtilFns.assertNonNull(
      newAt,
      'Proximity Account Organization Tree should have been found - bug'
    );
    if (!newAt.id) return;
    this.approvalTrees = this.approvalTrees.filter(
      (x) => x.id != UtilFns.EmptyGuid
    );
    const treeUserGroups = _.flatten(
      newAt.approvalTreeAdminGroups.map((x) =>
        x.approvalTreeUserGroups.map((y) => y.programUserGroup)
      )
    );
    const treeUgIdSet = new Set(treeUserGroups.map((ug) => ug.id));

    const paugs = _.flatten(
      this.program.programAllowances.map((pa) =>
        pa.programAllowanceUserGroupMaps.filter(
          (x) => !treeUgIdSet.has(x.programUserGroupId)
        )
      )
    );
    if (paugs.length > 0) {
      const errs = paugs
        .map(
          (x) =>
            `Program Allowance: '${x.programAllowance.name}' - User Group: '${x.programUserGroup.name}'`
        )
        .join('<br/>');
      await this.dialogService.okDialog(
        'Cannot change Proximity Account Organization Tree',
        `This Account Organization Tree does not contain the following user groups that are referenced within this program </br></br> ${errs} `
      );
      // Needed if we use a toast instead
      // this.appRef.tick();
      this.currentApprovalTreeId = this.program.approvalTreeId!;
      return;
    }
    const piugs = _.flatten(
      this.program.programIssuances.map((pa) =>
        pa.programIssuanceUserGroupMaps.filter(
          (x) => !treeUgIdSet.has(x.programUserGroupId)
        )
      )
    );
    if (piugs.length > 0) {
      const errs = piugs
        .map(
          (x) =>
            `Program Issuance: '${x.programIssuance.name}' - User Group: '${x.programUserGroup.name}'`
        )
        .join('<br/>');
      await this.dialogService.okDialog(
        'Cannot change Proximity Account Organization Tree',
        `This Account Organization Tree does not contain the following user groups that are referenced within this program </br></br> ${errs} `
      );
      // Needed if we use a toast instead
      this.appRef.tick();
      this.currentApprovalTreeId = <string>this.program.approvalTreeId;
      return;
    }

    this.program.approvalTree = newAt;
    // TODO: review if needed.
    await this.dbQueryService.getProgramUserGroupsForApprovalTree(
      <string>this.program.approvalTreeId
    );
  }

  /** When programPcardTypeId is changed */
  async onPcardChanged(evt: RadioButtonClickEvent) {
    this.pcardMessage = '';

    if (evt.value == ProgramPcardTypeEnum.ByProgramUserGroup) {
      const count = await this.dbQueryService.getProgramUserGroupsWithoutPcardsCount(this.program.accountId);
      if (count > 0) {
        this.pcardMessage = 'Some program user groups do not have p-cards assigned';
      }
      return;
    }

    if (evt.value == ProgramPcardTypeEnum.ByShippingUserGroup) {
      const count = await this.dbQueryService.getShippingUserGroupsWithoutPcardsCount(this.program.accountId);
      if (count > 0) {
        this.pcardMessage = 'Some shipping user groups do not have p-cards assigned';
      }
      return;
    }
  }

  /** When programPurchaseOrderTypeId is changed */
  async onPoChanged(evt: RadioButtonClickEvent) {
    this.poMessage = '';

    if (evt.value == ProgramPurchaseOrderTypeEnum.ByProgramUserGroup) {
      const count = await this.dbQueryService.getProgramUserGroupsWithoutPosCount(this.program.accountId);
      if (count > 0) {
        this.poMessage = 'Some program user groups do not have purchase orders assigned';
      }
      return;
    }

    if (evt.value == ProgramPurchaseOrderTypeEnum.ByShippingUserGroup) {
      const count = await this.dbQueryService.getShippingUserGroupsWithoutPosCount(this.program.accountId);
      if (count > 0) {
        this.poMessage = 'Some shipping user groups do not have purchase orders assigned';
      }
      return;
    }
  }

  async markError(errEnt: Entity, propName?: string) {
    UtilFns.focusInputByEntity('#topLevel', errEnt, propName || '');
  }

  toggleActivate() {
      if ((this.program.activeStatusId != ActiveStatusEnum.Active) && (this.program.activeStatusId != ActiveStatusEnum.Hold)) {
        return;
      }
  
      let  pendingStatusId: ActiveStatusEnum;
  
      if (this.program.activeStatusId == ActiveStatusEnum.Hold) {
        this.parent.isActivating = true;
        pendingStatusId = ActiveStatusEnum.Active;
      } else {
        this.parent.isActivating = false;
        this.program.activeStatusId = ActiveStatusEnum.Hold;
        pendingStatusId = ActiveStatusEnum.Hold;
      }

      
      this.dialogService.verifyProceedDialog(this, this.statusService.getStatusExplanation(pendingStatusId, 'Program'), 'Program Status Change');
    }
  
    async proceedDialog() {
      if (this.parent.isActivating) {
        await this.parent.tryActivate();
      } else {
        this.parent.onSave();
      }
    }
  
    cancelDialog() {
      this.parent.dbSaveService.rejectChanges();
    }

    statusName() {
      // return this.statusService.getWorkingStatus(this.program as any).shortDisplay;
      return this.program.activeStatus!.name;
    }

    statusButtonLabel() {
/*       if (this.statusService.getWorkingStatus(this.program as any).maxStatusEntity.getStatusId() == ActiveStatusEnum.Active) { */
      if (this.program.activeStatusId == ActiveStatusEnum.Active) {  
        return 'Place Program on Hold'
      } else if (this.program.activeStatusId == ActiveStatusEnum.Hold) {
        return 'Activate Program'
      }
      return 'Contact Principal Account Administrator'
    }
}
