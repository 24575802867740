/* eslint-disable @typescript-eslint/no-unused-vars */

import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgFns, DateCellEditorPngComponent, ISortModel, ProxAgFns, StatusService } from '@core';
import { AccountUser, ProgramApplicabilityTypeEnum, ProgramStartDateType, ProgramStartDateTypeEnum, ProgramUserGroup, ProgramUserGroupExclusion } from '@models';
import { ProgramFrmComponent } from './program-frm.component';
import * as _ from 'lodash';
import { EntityFns } from '@data';

@Component({
  selector: 'prox-program-user-groups-sub',
  templateUrl: './program-user-groups.sub.component.html',
})
export class ProgramUserGroupsSubComponent implements OnInit, OnChanges {
  @Input() visible!: boolean;
  @Input() parent!: ProgramFrmComponent;
  
  pugGridOptions: GridOptions = {};
  pugs: ProgramUserGroup[] = [];  
  selectedPug?: ProgramUserGroup;

  auGridOptions: GridOptions = {};
  aus: AccountUser[] = [];


  accountUserIdCountMap!: object;

  constructor( 
    private statusService: StatusService
  ) {
    //
  }

  get program() {
    return this.parent.program;
  }

  ngOnInit() {
    this.pugGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPugGridReady,
        onRowSelected: this.onPugRowSelected,
        rowSelection: 'single',
        rowModelType: 'clientSide',
      },
    );

    this.auGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onAuGridReady,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        enableCellTextSelection: true
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh(forceRefresh = true) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);
    this.pugs = this.program.approvalTree?.approvalTreeUserGroups.map(x => x.programUserGroup) ?? [];
    // Users associated with user groups are no longer queried until UserGroups sub is opened 
    // const pugms = _.flatMap(this.pugs, x => x.programUserGroupMaps);
    // this.accountUserIdCountMap = _.countBy(pugms, x => x.accountUserId);
    // this.pugs.forEach( x => {
    //   x.programUserGroupExclusions.filter(y => y.programId == this.program.id).forEach(y => this.accountUserIdCountMap[y.accountUserId] -= 1);
    // });

    if (_.isEmpty(this.pugGridOptions)) return;
    this.onPugGridReady();
    this.onAuGridReady();
    AgFns.refreshGrid(this.pugGridOptions, this.pugs);
    AgFns.refreshGrid(this.auGridOptions, this.aus);
  }

  onPugGridReady() {
    const colDefs = [
      {
        ...AgFns.createButtonProps(
          'View/Edit',
           this.parent.onGotoUserGroup.bind(this.parent),
          { label: 'View/Edit' }
        ),
        width: 160,
        maxWidth: 160,
      },
      {
        headerName: 'Group Name',
        field: 'name',
        minWidth: 500,
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Purchase Order',
        field: 'accountBlanketPurchaseOrder.purchaseOrder',
        minWidth: 300,
        filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Procurement Card',
        field: 'accountProcurementCard.name',
        minWidth: 300,
        filter: 'agTextColumnFilter'
      },
      ProxAgFns.getWorkingStatusDef('Status', this.statusService),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    
    AgFns.initGrid(this.pugGridOptions, colDefs, sortModel);
    AgFns.sizeColumnsToFit(this.pugGridOptions);
    AgFns.selectDefaultRow(this.pugGridOptions);
  }

  onAuGridReady() {
    const colDefs = [
      {
        ...AgFns.createButtonProps(
          'Exclude/Include?',
           this.excludeIncludeFromGroup.bind(this),
          { calcLabel: this.calcExcludeIncludeLabel.bind(this), calcDisabled: () => this.parent.isReadOnly() }
        ), maxWidth: 130, 
      },
      { headerName: '# Groups', 
        valueGetter: (params) => {
          const au = params.data as AccountUser;
          return this.accountUserIdCountMap[au.id];
        }, maxWidth: 100
      },
      {
        headerName: 'Last Name',
        field: 'proximityUser.lastName',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'First Name',
        field: 'proximityUser.firstName',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Middle Name',
        field: 'proximityUser.middleName',
        filter: 'agSetColumnFilter',
        maxWidth: 120
      },
      {
        headerName: 'Salutation',
        field: 'proximityUser.salutation',
        filter: 'agSetColumnFilter',
        maxWidth: 100
      },
      {
        headerName: 'Gender',
        field: 'gender.name',
        filter: 'agSetColumnFilter',
        maxWidth: 100
      },
      {
        headerName: 'Hire Date',
        field: 'hireDate',
        editable: !this.parent.isReadOnly(),
        maxWidth: 130,
        hide: (this.parent.program.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.ExistingUser),
        filter: 'agDateColumnFilter',
        cellEditor: DateCellEditorPngComponent
      },
      {
        headerName: 'New Hire End Date',
        field: 'newHireUntilDate',
        editable: !this.parent.isReadOnly(),
        maxWidth: 130,
        hide: (this.parent.program.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.ExistingUser),
        filter: 'agDateColumnFilter',
        cellEditor: DateCellEditorPngComponent
      },
      {
        headerName: 'Ann. Start Date',
        field: 'programAnniversaryDate',
        editable: !this.parent.isReadOnly(),
        maxWidth: 130,
        hide: (this.parent.program.programStartDateTypeId == ProgramStartDateTypeEnum.CalendarDate),
        filter: 'agDateColumnFilter',
        cellEditor: DateCellEditorPngComponent
      },
      {
        headerName: 'E-Mail',
        field: 'proximityUser.email',
        filter: 'agSetColumnFilter',
      },
       ProxAgFns.getWorkingStatusDef('Status', this.statusService)
    ];
    const sortModel: ISortModel = [{ colId: 'proximityUser.lastName', sort: 'asc' }];
    this.auGridOptions.rowClassRules = {
      "row-excluded": params => {
        const accountUser = params.data;
        const exclusion = this.selectedPug?.programUserGroupExclusions.find(x => x.accountUserId == accountUser.id && x.programId == this.program.id);
        return exclusion != null;
      },
    }

    AgFns.initGrid(this.auGridOptions, colDefs, sortModel);
  }

  async onPugRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const pug = event.data as ProgramUserGroup;
    

    if (pug == null) return;
    // 'programUserGroupMaps.accountUser.proximityUser',
    const pugms = await this.parent.dbQueryService.getProgramUserGroupMaps(pug.id);
    this.accountUserIdCountMap = _.countBy(pugms, x => x.accountUserId);
    
    pug.programUserGroupExclusions.filter(y => y.programId == this.program.id).forEach(y => this.accountUserIdCountMap[y.accountUserId] -= 1);

    this.selectedPug = pug;
    this.aus = pug.programUserGroupMaps.map(x => x.accountUser);
    AgFns.refreshGrid(this.auGridOptions, this.aus);
  }

  async excludeIncludeFromGroup(accountUser: AccountUser) {
    if (this.selectedPug == null) return;
    const exclusion = this.selectedPug?.programUserGroupExclusions.find(x => x.accountUserId == accountUser.id && x.programId == this.program.id);
    if (exclusion == null) {
      const item = this.parent.dbSaveService.uow.undoIfDeleted(ProgramUserGroupExclusion, [this.program.id, this.selectedPug.id, accountUser.id]);
      if (item == null) {
        this.parent.dbSaveService.createEntity(ProgramUserGroupExclusion, {
          programId: this.program.id,
          programUserGroupId: this.selectedPug.id,
          accountUserId: accountUser.id
        });
      }
      this.accountUserIdCountMap[accountUser.id] = this.accountUserIdCountMap[accountUser.id] - 1;
    } else {
      EntityFns.deleteOrDetach(exclusion.entityAspect);
      this.accountUserIdCountMap[accountUser.id] = this.accountUserIdCountMap[accountUser.id] + 1;
    }
    AgFns.refreshGrid(this.auGridOptions, this.aus);
  }

  calcExcludeIncludeLabel(accountUser: AccountUser ) {
    const exclusion = this.selectedPug?.programUserGroupExclusions.find(x => x.accountUserId == accountUser.id && x.programId == this.program.id);
    return exclusion != null ? 'Include' : 'Exclude';
  }
}
