/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService, Roles, SaveAndQueryComponent, StatusService } from '@core';
import {
  Account,
  AccountAdmin,
  AccountImportCodeOption,
  ActiveStatus,
  Program,
  ProximityAreaEnum,
  ProximityUser,
  ActiveStatusEnum,
  ProximityRole,
} from '@models';
import { DateFns, UtilFns } from '@utils';
import { AccountDbSaveService } from './services/account-db-save.service';
import { AccountDbQueryService } from './services/account-db-query.service';
import { AccountUnitOfWork } from '@data';
import { Entity, EntityError } from 'breeze-client';
import { DialogService } from 'primeng/dynamicdialog';
import { AccountActivationService } from './services/account-activation.service';

export class ImportOption {
  public constructor(init?: Partial<ImportOption>) {
    Object.assign(this, init);
  }
  id?: number;
  name?: string;
}

@Component({
  selector: 'prox-account-frm',
  templateUrl: './account-frm.component.html',
})
export class AccountFrmComponent extends SaveAndQueryComponent {
  accountId!: string;
  account!: Account;

  proximityUser!: ProximityUser;
  accountAdmin!: AccountAdmin;
  accountAdmins: AccountAdmin[] = [];
  filterAccountAdmins: AccountAdmin[] = [];
  areas = [ProximityAreaEnum.Accounts];
  allRoles: ProximityRole[] = [];
  activeStatuses: ActiveStatus[] = [];
  accountImportCodeOptions: AccountImportCodeOption[] = [];
  importOptions: ImportOption[] = [];
  deleteLabel = 'Delete this Account';

  isBeingAdded!: boolean;
  addedEntities: Entity[] = [];

  fiscalMonthNum: number = 1;
  fiscalMonthNums!: any[];
  fiscalDayNum: number = 1;
  fiscalDayNums!: any[];
  currentFiscalPeriod = '';

  isValidateActivated = false;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private uow: AccountUnitOfWork,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    public pngDialogService: DialogService,
    private statusService: StatusService,
    public activationService: AccountActivationService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object) {
    const accountId = params['accountId'];
    UtilFns.assertNonEmptyString(accountId, 'accountId');

    [this.activeStatuses, this.accountImportCodeOptions] = await Promise.all([
      this.dbQueryService.getAll(ActiveStatus),
      this.dbQueryService.getAll(AccountImportCodeOption),
    ]);

    this.isBeingAdded = accountId === 'add';
    

    if (this.isBeingAdded) {
      this.allRoles = (await this.dbQueryService.getAllRoles()).filter(p => p.name === Roles.PrincipalAccountAdmin);
    } else {
      this.allRoles = await this.dbQueryService.getAccountRolesByAccountId(accountId)
    }

    if (this.isBeingAdded) {
      this.createEntities();
    } else {
      this.account = await this.dbQueryService.getAccountByIdWithAdmins(accountId);
      this.accountAdmins = this.account.accountAdmins;
      this.filterAccountAdmins = this.account.accountAdmins.filter(
        (p) =>
          p.proximityUser.activeStatusId === ActiveStatusEnum.Active && p.proximityUser.proximityRole.name === Roles.PrincipalAccountAdmin
      );
    }
    UtilFns.assertNonNull(this.account, 'account');

    this.isValidateActivated = this.activationService.isActivated;

    this.accountImportCodeOptions.forEach((p) => {
      this.importOptions.push(
        new ImportOption({
          id: p.id,
          name: p.description,
        })
      );
    });

    this.fiscalMonthNums = [...Array(12).keys()].map((x) => {
      return { label: (x + 1).toString(), value: x + 1 };
    });

    this.fiscalDayNums = [...Array(28).keys()].map((x) => {
      return { label: (x + 1).toString(), value: x + 1 };
    });

    // if fiscalMonthStartDate was never set - set it now.
    if (this.account.fiscalMonthDayStartDate == null) {
      // year doesn't matter.
      this.account.fiscalMonthDayStartDate = DateFns.startOfMonth(new Date(2024, 1, 1));
      await this.dbSaveService.saveSelectedChanges([this.account]);
    }

    this.fmtCurrentFiscalPeriod();
    this.fiscalDayNum = this.account.fiscalMonthDayStartDate.getDate();
    this.fiscalMonthNum = this.account.fiscalMonthDayStartDate.getMonth() + 1;

    this.setTitle('Account Information');
    this.isPageReady = true;
  }

  override get entity() {
    return this.account;
  }

  /*   errorsString() {
    this.activationService.deactivate();
  } */

  fiscalDateChanged() {
    const fiscalDayNum = this.account.fiscalMonthDayStartDate.getDate();
    const fiscalMonthNum = this.account.fiscalMonthDayStartDate.getMonth() + 1;
    if (this.fiscalDayNum == fiscalDayNum && this.fiscalMonthNum == fiscalMonthNum) return;
    this.account.fiscalMonthDayStartDate = new Date(2024, this.fiscalMonthNum - 1, this.fiscalDayNum);
    this.fmtCurrentFiscalPeriod();
  }

  fmtCurrentFiscalPeriod() {
    const dt = this.account.getCurrentYearFiscalStartDate();
    this.currentFiscalPeriod = DateFns.fmtDate(dt) + ' to ' + DateFns.fmtDate(DateFns.dateAdd(DateFns.dateAdd(dt, 1, 'year'), -1, 'day'));
  }

  private createEntities() {
    this.account = this.dbSaveService.createEntity(Account, {
      activeStatusId: ActiveStatusEnum.Hold,
      startDate: new Date(),
      fiscalMonthDayStartDate: new Date(),

    });

    const role = this.allRoles.find((r) => r.name == Roles.PrincipalAccountAdmin);
    this.proximityUser = this.dbSaveService.createEntity(ProximityUser, {
      activeStatusId: ActiveStatusEnum.Active,
      proximityRoleId: role?.id,
    });

    this.accountAdmin = this.dbSaveService.createEntity(AccountAdmin, {
      accountId: this.account.id,
      proximityUserId: this.proximityUser.id,
    });

    // this.allRoles.forEach((p) => {
    //   if (p.areaId == 1) {
    //     const applied = this.dbSaveService.createEntity(ProximityUserRight, {
    //       proximityUserId: this.proximityUser.id,
    //       proximityRightId: p.id,
    //     });
    //     this.appliedUserRights.push(applied);
    //   }
    // });

    this.addedEntities = [this.account, this.accountAdmin, this.proximityUser];
    this.addedEntities.forEach((e) => {
      e.entityAspect.setUnchanged();
    });
  }

  override async addCrossValidationErrors(): Promise<void> {
    if (!this.isBeingAdded) {
      if (this.account.primaryAccountAdminId == null) {
        this.createValidationError(this.account, null, 'Active Proximity Accounts must have a Primary Administrator');
      } else {
        if (this.account.primaryAccountAdmin.getStatusId() != ActiveStatusEnum.Active) {
          this.createValidationError(this.account, null, 'Account Primary Admninistrator must be status: Active');
        }
        if (this.account.primaryAccountAdmin.getRoleName() != Roles.PrincipalAccountAdmin) {
          this.createValidationError(this.account, null, 'Account Principal Administrator must have PrincipalAdministrator privileges');
        }
      }
    } else {
      if (this.account.primaryAccountAdminId != null) {
        if (this.account.primaryAccountAdmin.getStatusId() != ActiveStatusEnum.Active) {
          this.createValidationError(this.account, null, 'Account Primary Admninistrator must be status: Active');
        }
        if (this.account.primaryAccountAdmin.getRoleName() != Roles.PrincipalAccountAdmin) {
          this.createValidationError(this.account, null, 'Account Principal Administrator must have PrincipalAdministrator privileges');
        }
      }
    }
  }

  statusButtonLabel() {
    if (this.isValidateActivated) {
      return 'Pending Activation...';
    }

    if (this.account.activeStatusId == ActiveStatusEnum.Active) {
      return 'Place Account on Hold';
    } else if (this.account.activeStatusId == ActiveStatusEnum.Hold) {
      return 'Activate Account';
    }
    return 'Contact Proximity Support';
  }

  disableViewAdministrator() {
    return this.dbSaveService.hasChanges() || !this.account.primaryAccountAdmin;
  }

  gotoAdministrator() {
    this.router.navigate(['account', 'accounts', this.account.id, 'manage', 'account-administrators', this.account.primaryAccountAdmin.id]);
  }

  override navigateToValidationError(error: EntityError) {
    const errEnt = error.entity;
    const prop = error.propertyName;
    UtilFns.focusInputByEntity('#topLevel', errEnt, prop);
  }

  override navigateBack(): void {
    this.router.navigate(['account/accounts']);
  }

  statusMessage(): string {
    if (this.account.activeStatusId == ActiveStatusEnum.Active) {
      return 'Account is Active and Not Editable.  To edit, set Account status to Hold.';
    } else if (this.account.activeStatusId == ActiveStatusEnum.Hold) {
      return 'Account is On-Hold and is Editable';
    } else {
      return 'Account is ' + this.account.activeStatus.name + ' and is Not Editable';
    }
  }

  public isReadOnly(): boolean {
    return this.statusService.getWorkingStatus(this.account as any).isActiveReadOnly;
  }

  okToDelete() {
    return !this.isBeingAdded && this.account.activeStatusId == ActiveStatusEnum.Hold;
  }

  async onDelete() {
    const inUse = await this.dbQueryService.checkIfInUse(this.account, Program, 'accountId');
    if (inUse) {
      this.toastr.warning(`You cannot delete an Account that has applied Programs.`, 'Cannot Delete');
      return;
    }

    const ok = await this.dialogService.askIfOk(
      'Delete Supplier',
      'Are you sure that you want to delete ' + this.account?.name + '?  (This action cannot be reversed)'
    );
    if (!ok) {
      return;
    }

    // insure that everything we want to delete is in the cache.
    await this.dbQueryService.getAccountByIdForDelete(this.account.id);

    // circular ref here - clear it first.
    this.account.primaryAccountAdminId = undefined;
    await this.dbSaveService.saveChanges();

    this.account.accountImages.slice().forEach((c) => {
      c.entityAspect.setDeleted();
    });

    this.account.accountAdmins.slice().forEach((c) => {
      c.proximityUser.entityAspect.setDeleted();
      c.entityAspect.setDeleted();
    });

    this.account.accountAdminGroups.slice().forEach((c) => {
      c.accountAdminGroupMaps.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.shippingUserGroups.slice().forEach((c) => {
      c.shippingUserGroupMaps.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.shippingUserGroupAddressMaps.slice().forEach((e) => {
        e.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.programUserGroups.slice().forEach((c) => {
      c.programUserGroupBudgets.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.programUserGroupMaps.slice().forEach((f) => {
        f.entityAspect.setDeleted();
      });
      c.programUserGroupExclusions.slice().forEach((g) => {
        g.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.accountUsers.slice().forEach((c) => {
      c.proximityUser.entityAspect.setDeleted();
      c.entityAspect.setDeleted();
    });

    this.account.budgetProductTypeTags.slice().forEach((c) => {
      c.budgetProductTypeTagMaps.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.programProductTypeTags.slice().forEach((c) => {
      c.programProductCategoryTagMaps.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.programProductTypeTagMaps.slice().forEach((e) => {
        e.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.accountAddresses.slice().forEach((c) => {
      c.entityAspect.setDeleted();
    });

    this.account.entityAspect.setDeleted();

    const r = await this.dbSaveService.saveChanges();
    this.toastr.success('Account was successfully deleted', 'Database Activity');
    this.navigateBack();
  }

  async toggleActivate() {
    if (this.isBeingAdded) {
      return;
    }

    if (this.account.activeStatusId != ActiveStatusEnum.Active && this.account.activeStatusId != ActiveStatusEnum.Hold) {
      return;
    }

    if (this.account.activeStatusId === ActiveStatusEnum.Hold) {
      if (await this.activationService.activate(this.account.id)) {
        const ok = await this.dialogService.askIfOk(
          'Cannot Activate',
          'Account cannot be activated.  Switch to Account Activation Mode for fixes?'
        );
        if (ok) {
          this.isValidateActivated = true;
          return;
        } else {
          this.isValidateActivated = false;
          this.activationService.deactivate();
          return;
        }
      }
    }

    if (this.account.activeStatusId == ActiveStatusEnum.Active) {
      this.account.activeStatusId = ActiveStatusEnum.Hold
    } else {
      this.account.activeStatusId = ActiveStatusEnum.Active
    }
    this.dialogService.verifyProceedDialog(this, this.statusService.getStatusExplanation(this.account.activeStatusId, 'Account'), 'Account Status Change');
  }

  async proceedDialog() {
    await this.dbSaveService.saveChanges();
    this.toastr.success('Status change updated', 'Database Activity');
  }

  cancelDialog() {
    this.dbSaveService.rejectChanges();
  }

  override async beforeSave() {
    if (this.isBeingAdded) {
      this.addedEntities.forEach((e) => e.entityAspect.setAdded());
      const ok = await this.dbQueryService.checkIfIsUnique(this.proximityUser, 'id', 'username');
      if (!ok) {
        this.toastr.error('This Administrator Login Name has already been taken.', 'Unable to Add Account');
        return false;
      }
    }
    return true;
  }

  override async afterSave() {
    if (this.isBeingAdded) {
      // because of circular dependency issues.
      this.account.primaryAccountAdminId = this.accountAdmin.id;
      await this.dbSaveService.saveChanges();
      this.isBeingAdded = false; 
      this.router.navigate(['account/accounts', this.account.id, 'manage']);
    }

    if (this.isValidateActivated) {
      if (!await this.activationService.activate(this.account.id)) {
        this.account.activeStatusId = ActiveStatusEnum.Active;
        this.activationService.deactivate();
        this.isValidateActivated = false;
        await this.dbSaveService.saveChanges();
      }
    }
  }  

  override async afterUndo() {
    if (this.isBeingAdded) {
      this.createEntities();
    }
  }
}
