/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component
} from '@angular/core';
import { AgFns, EntityFinderDialog, EntityFinderParams, ISortModel } from '@core';

import { NotificationEventEnum, NotificationTemplate } from '@models';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { UtilFns } from '@utils';
import { ColDef } from '@ag-grid-community/core';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { NotificationTemplateDialogComponent } from './notification-template-dialog.component';
import { AccountDbSaveService } from '../services/account-db-save.service';

export interface NotificationTempleteFinderParams extends EntityFinderParams {
  accountId: string,
  notificationTemplates: NotificationTemplate[];

}

@Component({
  selector: 'prox-notification-template-finder',
  templateUrl: './notification-template-finder.dialog.html',  // This finder has buttons not on the default 'finder' dialog. 
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NotificationTemplateFinderDialog extends EntityFinderDialog<NotificationTemplate, NotificationTempleteFinderParams> {

  params: NotificationTempleteFinderParams;
  notificationTemplates: NotificationTemplate[] = [];
  
  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig<NotificationTempleteFinderParams>,
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public pngDialogService: DialogService
  ) {
    super(ref, config);
    UtilFns.assert(config.data);
    this.params = config.data;
    UtilFns.assertNonNull(this.params.notificationTemplates, 'notificationTemplates');
  }

  override async initialize() {
    
    return {
      header: `Select Notification Template`,
      width: 'calc(40vw)',
      rowModelType: 'clientSide'
    } as const;
    
  }

  override async initDatasource() {
    return this.params.notificationTemplates;
  }

  override getColDefsAndSortModel() {
    const colDefs = [

      { headerName: 'Template Name', colId: 'templateName', filter: 'agTextColumnFilter', checkboxSelection: this.initParams.rowSelection == 'multiple', 
        valueGetter: (params) => {
          const nt = params.data as NotificationTemplate;
          if (nt == null) return;
          return nt.templateName ?? nt.notificationEvent.description;
       },
      },
      { headerName: 'Type', field: 'notificationType.name', filter: 'agTextColumnFilter',  },
      {
        ...AgFns.createIconProps('Global', '', () => null, {
          label: '',
          getIcon: this.getIsGlobalIcon.bind(this),
        }),
        width: 70,
        maxWidth: 70,
      },
      {
        ...AgFns.createButtonProps(' ', this.onView.bind(this), {
          label: 'View',
        }),
        width: 60,
        maxWidth: 60,
      },
      { headerName: 'Template', field: 'template', filter: 'agTextColumnFilter',  },
    ] as ColDef[]
    const sortModel = [
      { colId: 'templateName', sort: 'asc' },
    ] as ISortModel
    return [ colDefs, sortModel] as const;
  }

  getIsGlobalIcon(row: NotificationTemplate) {
    if (!row.accountId) {
      return 'pi pi-check';
    } else {
      return '';
    }
  }

  async onAddTemplate() {
    const nt = this.dbSaveService.createEntity(NotificationTemplate, {
          accountId:  this.params.accountId, 
          notificationEventId: NotificationEventEnum.FreeForm
        });
    const ok = await NotificationTemplateDialogComponent.openDialog(this.pngDialogService, 
      { notificationTemplate: nt, isNew: true,    });
    if (ok) {
      await this.dbSaveService.saveSelectedChanges([nt]);
      const nts = this.params.notificationTemplates;
      nts.push(nt)
      const gridApi = this.gridOptions.api;
      gridApi?.setRowData(nts);
    } else {
      this.dbSaveService.rejectChanges();
    }
  }

  async onView(nt: NotificationTemplate) {
    const ok = await NotificationTemplateDialogComponent.openDialog(this.pngDialogService, { notificationTemplate: nt  },   );
  }
}