<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div class="card-body flex flex-grow-1 flex-column gap-3">
    <div>
      <h5>Program Information</h5>
    </div>
    <div class="flex align-content-start ">
      <div class="card-body flex-column flex-grow-1 gap-3">

        <div class="w-12">
          <prox-field label="Name">
            <input type="text" [disabled]="parent.isReadOnly()" pInputText [(ngModel)]="program.name"
              [bzModel]="program" name="name">
          </prox-field>
        </div>

        <div class="flex flex-row gap-3">
          <div class="w-6 mt-3">
            <prox-field label="Program Type">
              <p-dropdown [options]="programTypes" optionLabel="name" optionValue="id" (onChange)="onProgramTypeChange($event)"
                [disabled]="parent.isReadOnly() || !this.parent.isBeingAdded" [(ngModel)]="program.programTypeId"
                [bzModel]="program" name="programTypeId"></p-dropdown>
            </prox-field>
          </div>
          <div class="w-6 mt-3">
            <prox-field label="Applies to">
              <p-dropdown [disabled]="parent.isReadOnly()" [options]="programApplicabilityTypes" optionLabel="name"
                optionValue="id" (onChange)="onProgramApplicabilityTypeChanged($event.value)"
                [(ngModel)]="program.programApplicabilityTypeId" [bzModel]="program"
                name="programApplicabilityTypeId"></p-dropdown>
            </prox-field>
          </div>
        </div>

        <div class="flex flex-column mt-3">
          <div class="w-12 mt-3">
            <prox-field label="Description">
              <textarea [disabled]="parent.isReadOnly()" rows="2" cols="30" pInputTextarea
                [(ngModel)]="program.description" [bzModel]="program" name="description"></textarea>
            </prox-field>
          </div>

          <div class="flex flex-row gap-3">
            <div class="w-6 mt-3">
              <prox-field label="Start Date Type">
                <p-dropdown [options]="programStartDateTypes" optionLabel="name" optionValue="id"
                  (onChange)="onProgramStartDateTypeChanged($event.value)" [disabled]="parent.isReadOnly()"
                  [(ngModel)]="program.programStartDateTypeId" [bzModel]="program" name="programStartDateTypeId">
                </p-dropdown>
              </prox-field>
            </div>
            <div class="w-4 mt-3" *ngIf="program.programStartDateTypeId === ProgramStartDateTypeEnum.CalendarDate">
              <prox-field label="Start Date">
                <p-calendar [disabled]="parent.isReadOnly() || !this.parent.isBeingAdded"
                  [(ngModel)]="program.startDate" [bzModel]="program" name="startDate"></p-calendar>
              </prox-field>
            </div>
            <div class="w-4 mt-3" *ngIf="program.programStartDateTypeId === ProgramStartDateTypeEnum.CalendarDate">
              <prox-field [ngStyle]="{'color': isExpired ? 'red' : ''}" label="End Date">
                <p-calendar [disabled]="(parent.isReadOnly())" [(ngModel)]="program.endDate" [bzModel]="program"
                  name="endDate"></p-calendar>
              </prox-field>
            </div>
            <div class="w-4 mt-3" *ngIf="program.programStartDateTypeId === ProgramStartDateTypeEnum.AnniversaryDate">
              <prox-field label="# days program starts after anniversary">
                <p-inputNumber [disabled]="(parent.isReadOnly())" [(ngModel)]="program.startAfterAnniversaryNumDays"
                  [bzModel]="program" name="startAfterAnniversaryNumDays"></p-inputNumber>
              </prox-field>
            </div>
            <div class="w-4 mt-3" *ngIf="program.programStartDateTypeId === ProgramStartDateTypeEnum.AnniversaryDate">
              <prox-field label="# days program ends after anniversary">
                <p-inputNumber [disabled]="(parent.isReadOnly())" [(ngModel)]="program.endsAfterAnniversaryNumDays"
                  [bzModel]="program" name="endsAfterAnniversaryNumDays"></p-inputNumber>
              </prox-field>
            </div>
          </div>

          <div class="flex flex-row gap-3">
            <div class="w-6 mt-4">
              <prox-field label="Shipping Frequency">
                <p-dropdown [disabled]="parent.isReadOnly()" [options]="shipFrequencies" optionLabel="name"
                  optionValue="id" [(ngModel)]="program.shipFrequencyId" [bzModel]="program"
                  name="shipFrequencyId"></p-dropdown>
              </prox-field>
            </div>
            <div class="w-7 mt-4">
              <prox-field label="Proximity Organization Tree">
                <p-dropdown [disabled]="parent.isReadOnly()" [options]="approvalTrees" optionLabel="name"
                  optionValue="id" [(ngModel)]="currentApprovalTreeId" (onChange)="onApprovalTreeChanged($event.value)"
                  [bzModel]="program" name="approvalTreeId"></p-dropdown>
              </prox-field>
            </div>
            <div class="pt-6">
              <p-button styleClass="p-button p-button-sm" [disabled]="parent.hasChanges()"
                (onClick)="parent.onGotoApprovalTree(this.program.approvalTree)" title="View">View</p-button>
            </div>
          </div>

          <div class="flex flex-row">
            <div class="flex flex-column mt-4 w-8">
              <prox-field label="Purchase Order Type">
                <div class="flex items-left mt-2 gap-2">
                  <div *ngFor="let poType of programPurchaseOrderTypes" class="field-checkbox">
                    <p-radioButton name="programPurchaseOrderType" [value]="poType.id" [disabled]="parent.isReadOnly()"
                      [bzModel]="program" [(ngModel)]="program.programPurchaseOrderTypeId" (onClick)="onPoChanged($event)" />
                    <label [for]="poType.name" class="ml-2">
                      {{ poType.description }}
                    </label>
                  </div>
                </div>
              </prox-field>
            </div>
            <div class="w-4 mt-4" *ngIf="program.programPurchaseOrderTypeId === 2">
              <prox-field label="Program Purchase Order">
                <p-dropdown [disabled]="parent.isReadOnly()" [options]="blanketPos" optionLabel="purchaseOrder"
                  optionValue="id" [(ngModel)]="program.accountBlanketPurchaseOrderId" [bzModel]="program"
                  name="accountBlanketPurchaseOrderId"></p-dropdown>
              </prox-field>
            </div>
            <div class="w-4 mt-4" *ngIf="poMessage">
              <div class="text-red-500 mt-4">{{ poMessage }}</div>
            </div>
          </div>

          <div class="w-6">
            <p-checkbox [disabled]="parent.isReadOnly()" [(ngModel)]="program.isPurchaseOrderRequired" [binary]="true"
              [bzModel]="program" name="isPurchaseOrderRequired"
              label="A purchase order is required for every order"></p-checkbox>
          </div>

          <div class="flex flex-row">
            <div class="flex flex-column mt-4 w-8">
              <prox-field label="Procurement Card (p-card) Type">
                <div class="flex items-left mt-2 gap-2">
                  <div *ngFor="let pcType of programPcardTypes" class="field-checkbox">
                    <p-radioButton name="programPcardType" [value]="pcType.id" [disabled]="parent.isReadOnly()"
                      [bzModel]="program" [(ngModel)]="program.programPcardTypeId" (onClick)="onPcardChanged($event)" />
                    <label [for]="pcType.name" class="ml-2">
                      {{ pcType.description }}
                    </label>
                  </div>
                </div>
              </prox-field>
            </div>

            <div class="w-4 mt-4" *ngIf="program.programPcardTypeId === 2">
              <prox-field label="Program Purchase Card">
                <p-dropdown [disabled]="parent.isReadOnly()" [options]="pcards" optionLabel="namedesc" optionValue="id"
                  [(ngModel)]="program.accountProcurementCardId" [bzModel]="program"
                  name="accountProcurementCard"></p-dropdown>
              </prox-field>
            </div>
            <div class="w-4 mt-4" *ngIf="pcardMessage">
              <div class="text-red-500 mt-4">{{ pcardMessage }}</div>
            </div>
          </div>

          <div class="w-6">
            <p-checkbox [disabled]="parent.isReadOnly()" [(ngModel)]="program.isPcardRequired" [binary]="true"
              [bzModel]="program" name="isPcardRequired"
              label="A procurement card (p-card) is required for every order"></p-checkbox>
          </div>

          <div class="flex flex-column gap-3 mt-3">
            <div class="w-6 pt-3">
              <p-checkbox [disabled]="parent.isReadOnly()" [(ngModel)]="program.doAllOrdersRequireApproval"
                [binary]="true" [bzModel]="program" name="doAllOrdersRequireApproval"
                label="Every order requires approval"></p-checkbox>
            </div>
            <div class="w-6">
              <p-checkbox [disabled]="parent.isReadOnly()" [(ngModel)]="program.canShipHome" [binary]="true"
                [bzModel]="program" name="canShipHome"
                label="Orders may ship to Account User home address"></p-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-column mt-3 w-4 ml-4">
        <div style="width: 300px;">
          <p-panel header="Current Status">
            <h3>{{statusName()}}</h3>
            <p-button size="small" (click)="toggleActivate()" label={{statusButtonLabel()}}></p-button>
          </p-panel>
        </div>
      </div>
    </div>
  </div>
</div>