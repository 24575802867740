/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationRef, Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService, BreadcrumbService, BusyService, SaveAndQueryComponent, StatusService } from '@core';
import {
  Program,
  Account,
  ActiveStatusEnum,
  ProgramUserGroup,
  ProgramProductTypeConfig,
  ProgramIssuance,
  ProgramAllowance,
  ProgramTypeEnum,
  ProgramBudget,
  ApprovalTree,
  ProgramStartDateTypeEnum,
  ProgramApplicabilityTypeEnum,
  ProgramView,
  ProgramRapidTemplate,
  ProgramViolationRuleEnum,
  ProgramProductTypeTag,
  ProgramProductCategoryTag,
  ProximityRightEnum,
  ProgramAllowanceFeatureException,
  ProgramAllowanceUserGroupMap,
  ProgramIssuanceUserGroupMap,
  ProgramProductCategoryTagMap,
  ProgramRapidTemplateProductTypeConfig,
  ProgramViewProductTypeConfig,
  ProgramAccountIssuanceMap,
  ProgramSupplier,
  ProgramPurchaseOrderTypeEnum,
  ProgramAllowanceAddonException,
  ProgramPcardTypeEnum,
  ProgramAllowanceRemainderRuleEnum,
} from '@models';

import { EntityFns } from '@data';
import { UtilFns } from '@utils';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { Entity, EntityError } from 'breeze-client';
import { MenuItem } from 'primeng/api';
import { Guid } from 'guid-typescript';
import * as _ from 'lodash';
import { AccountActivationService } from '../services/account-activation.service';

enum ViewEnum {
  None,
  Info,
  UserGroups,
  ProductTypeConfigs,
  ProductTags,
  MenuCategories,
  ProductViews,
  Issuances,
  Allowances,
  RapidTemplates,
  Budgets,
  Billing,
  Suppliers,
  Cancellation,
  Communications,
}

export interface ISubComponent {
  visible: boolean;
  refresh: (forceRefresh: boolean) => void;
  markError?: (errEnt: Entity, propName?: string) => void;
}

interface IComponentNameMap {
  [index: string]: ISubComponent;
}

// TODO: handle changes to productTypeConfigs and programProductTags in other subcomponents.

@Component({
  selector: 'prox-program-frm',
  templateUrl: './program-frm.component.html',
})
export class ProgramFrmComponent extends SaveAndQueryComponent implements OnDestroy {
  @ViewChildren('programSub') subComponents!: QueryList<ISubComponent>;

  // ProgramStartDateTypeEnum = ProgramStartDateTypeEnum;
  ViewEnum = ViewEnum;
  componentNameMap: IComponentNameMap = {};
  activeView = ViewEnum.Info;
  accountId!: string;
  incomingProgramId!: string;
  account!: Account;
  program!: Program;

  allAccountTags: ProgramProductTypeTag[] = [];
  private authCanEdit = false;
  isBeingAdded!: boolean;
  pptts: ProgramProductTypeTag[] = [];

  items: MenuItem[] = [];
  items2: MenuItem[] = [];
  isActivating = false;

  // programProductTypeConfigs: ProgramProductTypeConfig[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    public busyService: BusyService,
    private appRef: ApplicationRef,
    private statusService: StatusService,
    private activationService: AccountActivationService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  /** If EDIT mode, get the entity from dbQueryService; if ADD mode, call createEntity() */
  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    this.incomingProgramId = params['programId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    UtilFns.assertNonEmptyString(this.incomingProgramId, 'programId');
    this.isActivating = !!this.route.snapshot.queryParams['isActivating'];

    this.isBeingAdded = this.incomingProgramId === 'add';
    if (this.isBeingAdded) {
      this.program = this.createNewProgram();
      // it was 'add' coming into this.
    } else {
      this.program = await this.dbQueryService.getProgramById(this.incomingProgramId);
    }

    this.buildMainMenu();

    UtilFns.assertNonNull(this.authUser);
    this.authCanEdit = this.authUser.hasRight(ProximityRightEnum.CanEditProgram);

    const [account, pptts] = await Promise.all([
      this.dbQueryService.getAccountById(this.accountId),
      this.dbQueryService.getProgramProductTypeTagsForAccount(this.program.accountId),

      // we don't need to join to them anywhere - just retrieve them into cache for later
      this.dbQueryService.getProgramProductTypeConfigsByProgram(this.program.id),
      this.dbQueryService.getProgramUserGroupsForApprovalTree(this.program.approvalTreeId!),
    ]);

    this.activationService.deactivate();

    this.account = account;
    this.pptts = pptts;
    this.allAccountTags = pptts;

    this.setTitle('Proximity Program for ' + this.account.name);

    const crumbs = [
      { label: 'List Accounts', routerLink: ['/account/accounts'] },
      { label: 'Manage Account: ' + this.account.name, routerLink: ['/account/accounts', this.account.id, 'manage', 'programs'] },
      { label: 'Programs for Account: ' + this.account.name },
    ];
    if (!this.authUser?.hasRight(ProximityRightEnum.IsSuperUserForAccounts)) {
      crumbs.shift();
    }
    this.breadcrumbService.setItems(crumbs);

    if (this.isActivating) {
      const ok = await this.tryActivate();
      if (ok) {
        this.navigateBack();
        return;
      }
    }
    this.isPageReady = true;
  }

  public async tryActivate() {
    this.isActivating = true;
    const errEntities = await this.validateEntities();
    if (errEntities.length > 0) {
      this.isPageReady = true;
      this.toastr.info('Unable to activate - Fix the following errors first');
      await this.handleBreezeValidationErrors(errEntities);
    } else {
      this.program.activeStatusId = ActiveStatusEnum.Active;
      await this.saveChanges();
    }
    return this.program.activeStatusId == ActiveStatusEnum.Active;
  }

  public override ngOnDestroy(): void {
    const crumbs = [{ label: 'List Accounts', routerLink: ['/account/accounts'] }, { label: 'Manage Account: ' + this.account.name }];
    if (!this.authUser?.hasRight(ProximityRightEnum.IsSuperUserForAccounts)) {
      crumbs.shift();
    }
    this.breadcrumbService.setItems(crumbs);
  }

  public isReadOnly() {
    return !this.authCanEdit || !(this.program.activeStatusId == ActiveStatusEnum.Hold);
  }

  statusMessage(): string {
    let msg = '';
    if (this.program.activeStatusId == ActiveStatusEnum.Active) {
      return 'Program is Active and Not Editable.  To edit, set Program status to Hold.';
    } else if (this.program.activeStatusId == ActiveStatusEnum.Hold) {
      msg = 'Program is On-Hold and is Editable';
    } else {
      return 'Program is ' + this.program.activeStatus.name + ' and is Not Editable';
    }

    if (this.isActivating) {
      msg = msg + ' - Activation pending clearance of validation errors.';
    }
    return msg;
  }

  refreshAllSubs() {
    for (const subComponent of this.subComponents) {
      subComponent.refresh(true);
    }
  }

  ifEditable<T>(colDef: T) {
    return this.isReadOnly() ? ({} as T) : colDef;
  }

  // called from each subcomponent
  updateComponentNameMap(subComponent: ISubComponent) {
    if (!subComponent.visible) return;
    const name = ViewEnum[this.activeView];
    this.componentNameMap[name] = subComponent;
  }

  public buildMainMenu() {
    /*     if (!this.program!.programType) {
      this.program.programTypeId = ProgramTypeEnum.Managed;
    } */

    if (this.program.programTypeId == ProgramTypeEnum.Managed) {
      this.items = [
        {
          label: 'Information',
          icon: 'pi pi-fw pi-cog',
          command: () => {
            this.showPanel(ViewEnum.Info);
          },
        },
        {
          label: 'Supplier Settings',
          icon: 'fa-regular fa-file-invoice',
          command: () => {
            this.showPanel(ViewEnum.Suppliers);
          },
        },
        {
          label: 'Program User Groups',
          icon: 'pi pi-fw pi-user',
          command: () => {
            this.showPanel(ViewEnum.UserGroups);
          },
        },
        {
          label: 'Products and Tags',
          icon: 'fa-regular fa-box',
          command: () => {
            this.showPanel(ViewEnum.ProductTypeConfigs);
          },
        },
        /*       {
        label: 'Product Tags',
        icon: 'fa-regular fa-box',
        command: () => {
          this.showPanel(ViewEnum.MenuCategories);
        },
      }, */
        {
          label: 'Menu Categories',
          icon: 'fa-regular fa-box',
          command: () => {
            this.showPanel(ViewEnum.ProductTags);
          },
        },
        /*         {
          label: 'Compliance',
          hide: true,
          icon: 'fa-regular fa-eye',
          command: () => {
            this.showPanel(ViewEnum.ProductViews);
          },
        }, */
        {
          label: 'Issuance Limits',
          icon: 'fa-regular fa-calculator',
          command: () => {
            this.showPanel(ViewEnum.Issuances);
          },
        },
        {
          label: 'Allowances',
          icon: 'fa-regular fa-dollar-sign',
          command: () => {
            this.showPanel(ViewEnum.Allowances);
          },
        },
        {
          label: 'Rapid Orders',
          icon: 'fa-regular fa-registered',
          command: () => {
            this.showPanel(ViewEnum.RapidTemplates);
          },
        },
        {
          label: 'Budgets',
          icon: 'fa-regular fa-table',
          command: () => {
            this.showPanel(ViewEnum.Budgets);
          },
        },
        {
          label: 'Billing',
          icon: 'fa-regular fa-file-invoice',
          command: () => {
            this.showPanel(ViewEnum.Billing);
          },
        },
        /*       {
        label: 'Communications',
        icon: 'fa-regular fa-file-invoice',
        command: () => {
          this.showPanel(ViewEnum.Communications);
        },
      }, */
        {
          separator: true,
        },
        {
          label: 'Copy and Cycle',
          icon: 'fa-regular fa-copy',
          command: () => {
            this.onCopyProgram();
          },
        },
        /*       {
        label: 'Cancellation',
        icon: 'fa-regular fa-power-off',
        command: () => {
          this.showPanel(ViewEnum.Cancellation);
        },
      }, */
      ];
    } else {
      this.items = [
        {
          label: 'Information',
          icon: 'pi pi-fw pi-cog',
          command: () => {
            this.showPanel(ViewEnum.Info);
          },
        },
        {
          label: 'Supplier Settings',
          icon: 'fa-regular fa-file-invoice',
          command: () => {
            this.showPanel(ViewEnum.Suppliers);
          },
        },
        {
          label: 'Program User Groups',
          icon: 'pi pi-fw pi-user',
          command: () => {
            this.showPanel(ViewEnum.UserGroups);
          },
        },
        {
          label: 'Products and Tags',
          icon: 'fa-regular fa-box',
          command: () => {
            this.showPanel(ViewEnum.ProductTypeConfigs);
          },
        },
        {
          label: 'Menu Categories',
          icon: 'fa-regular fa-box',
          command: () => {
            this.showPanel(ViewEnum.ProductTags);
          },
        },
        {
          label: 'Compliance',
          hide: true,
          icon: 'fa-regular fa-eye',
          command: () => {
            this.showPanel(ViewEnum.ProductViews);
          },
        },
        {
          label: 'Budgets',
          icon: 'fa-regular fa-table',
          command: () => {
            this.showPanel(ViewEnum.Budgets);
          },
        },
        {
          label: 'Billing',
          icon: 'fa-regular fa-file-invoice',
          command: () => {
            this.showPanel(ViewEnum.Billing);
          },
        },
        {
          separator: true,
        },
        {
          label: 'Copy and Cycle',
          icon: 'fa-regular fa-copy',
          command: () => {
            this.onCopyProgram();
          },
        },
      ];
    }

    this.items2 = [
      {
        label: 'Copy and Cycle',
        icon: 'fa-regular fa-copy',
        command: () => {
          this.onCopyProgram();
        },
      },
    ];
  }

  async showPanel(selectedViewEnum: ViewEnum) {
    this.activeView = selectedViewEnum;
  }

  isView(viewEnum: ViewEnum) {
    return this.activeView == viewEnum;
  }

  private createNewProgram() {
    this.program = this.dbSaveService.createEntity(Program, {
      accountId: this.accountId,
      activeStatusId: ActiveStatusEnum.Hold,
      programTypeId: ProgramTypeEnum.Managed,
      //approvalTreeId: UtilFns.EmptyGuid,
      programApplicabilityTypeId: ProgramApplicabilityTypeEnum.Both,
      programAllowanceRemainderRuleId: ProgramAllowanceRemainderRuleEnum.RemainingAllowanceAddedToNewAllowances,
      programStartDateTypeId: ProgramStartDateTypeEnum.CalendarDate,
      issuanceProgramViolationRuleId: ProgramViolationRuleEnum.Warn,
      allowanceProgramViolationRuleId: ProgramViolationRuleEnum.Warn,
    });
    // this.program.entityAspect.validateEntity();
    this.program.entityAspect.setUnchanged();
    return this.program;
  }

  async onCopyProgram() {
    this.toastr.info('Under construction...');
    return;

    if (this.dbSaveService.hasChanges()) {
      this.toastr.warning('You must save/undo before copying this Proximity Program. Save/undo first.', 'Unable to Proceed');
      return;
    }

    if (this.program.activeStatusId == ActiveStatusEnum.Active || this.program.activeStatusId == ActiveStatusEnum.Hold) {
      this.toastr.warning('Programs must be set to Status Phaseout or Inactive to copy.', 'Unable to Proceed');
      return;
    }

    // Insure that everything we need to copy is here.
    await this.dbQueryService.getProgramByIdFull(this.program.id);

    const uow = this.dbSaveService.uow;
    const newProgram = EntityFns.copyEntity(
      uow,
      Program,
      this.program,
      ['id', 'name', 'startDate', 'accountBlanketPurchaseOrderId'],
      (e) => {
        e.name = this.program.name + ' - Copy';
        e.activeStatusId = ActiveStatusEnum.Hold;
      }
    );

    this.program.programAccountIssuanceMaps.forEach((tag) => {
      const newMap = EntityFns.copyEntity(uow, ProgramAccountIssuanceMap, tag, ['programId'], (e) => {
        e.programId = newProgram.id;
      });
    });

    this.program.programProductCategoryTags.forEach((tag) => {
      const newTag = EntityFns.copyEntity(uow, ProgramProductCategoryTag, tag, ['id', 'programId'], (e) => {
        e.programId = newProgram.id;
      });
      tag.programProductCategoryTagMaps.forEach((x) => {
        EntityFns.copyEntity(uow, ProgramProductCategoryTagMap, x, ['programProductCategoryTagId'], (e) => {
          e.programProductCategoryTagId = newTag.id;
        });
      });
    });

    this.program.programAllowances.forEach((pa) => {
      const newPa = EntityFns.copyEntity(uow, ProgramAllowance, pa, ['id', 'programId'], (e) => {
        e.programId = newProgram.id;
      });
      pa.programAllowanceAddonExceptions.forEach((x) => {
        EntityFns.copyEntity(uow, ProgramAllowanceAddonException, x, ['programAllowanceId'], (e) => {
          e.programAllowanceId = newPa.id;
        });
      });
      pa.programAllowanceFeatureExceptions.forEach((x) => {
        EntityFns.copyEntity(uow, ProgramAllowanceFeatureException, x, ['programAllowanceId'], (e) => {
          e.programAllowanceId = newPa.id;
        });
      });
      pa.programAllowanceUserGroupMaps.forEach((x) => {
        EntityFns.copyEntity(uow, ProgramAllowanceUserGroupMap, x, ['programAllowanceId'], (e) => {
          e.programAllowanceId = newPa.id;
        });
      });
    });

    this.program.programIssuances.forEach((pi) => {
      const newPi = EntityFns.copyEntity(uow, ProgramIssuance, pi, ['id', 'programId'], (e) => {
        e.programId = newProgram.id;
      });
      pi.programIssuanceUserGroupMaps.forEach((x) => {
        EntityFns.copyEntity(uow, ProgramIssuanceUserGroupMap, x, ['programIssuanceId'], (e) => {
          e.programIssuanceId = newPi.id;
        });
      });
    });

    this.program.programViews.forEach((pv) => {
      const newPv = EntityFns.copyEntity(uow, ProgramView, pv, ['id', 'programId'], (e) => {
        e.programId = newProgram.id;
      });
      pv.programViewProductTypeConfigs.forEach((x) => {
        EntityFns.copyEntity(uow, ProgramViewProductTypeConfig, x, ['programViewId'], (e) => {
          e.programViewId = newPv.id;
        });
      });
    });

    this.program.programProductTypeConfigs.forEach((ptConfig) => {
      const newPtConfig = EntityFns.copyEntity(uow, ProgramProductTypeConfig, ptConfig, ['id', 'programId'], (e) => {
        e.programId = newProgram.id;
      });
    });

    this.program.programBudgets.forEach((budget) => {
      const newBudget = EntityFns.copyEntity(uow, ProgramBudget, budget, ['id', 'programId'], (e) => {
        e.programId = newProgram.id;
      });
    });

    this.program.programRapidTemplates.forEach((rapidTemplate) => {
      const newRapidTemplate = EntityFns.copyEntity(uow, ProgramRapidTemplate, rapidTemplate, ['id', 'programId'], (e) => {
        e.programId = newProgram.id;
      });
      rapidTemplate.programRapidTemplateProductTypeConfigs.forEach((x) => {
        EntityFns.copyEntity(uow, ProgramRapidTemplateProductTypeConfig, x, ['programRapidTemplateId'], (e) => {
          e.programRapidTemplateId = newRapidTemplate.id;
        });
      });
    });

    newProgram.entityAspect.setUnchanged();
    this.isBeingAdded = true;
    this.program = newProgram;

    // important to insure they all reflect the new ids
    this.refreshAllSubs();
    return this.program;
  }

  async onProgramDelete(program: Program) {
    this.toastr.warning('Not yet implemented', 'In Progress');
    return;

    // TODO: need to write this.
    // may be better to do this on the server.

    program.programProductTypeConfigs.slice().forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
    program.programIssuances.slice().forEach((x) => {
      x.programIssuanceUserGroupMaps.slice().forEach((y) => EntityFns.deleteOrDetach(y.entityAspect));
      EntityFns.deleteOrDetach(x.entityAspect);
    });

    EntityFns.deleteOrDetach(program.entityAspect);
    const sr = await this.dbSaveService.saveChanges();

    this.toastr.success('Program deleted.');
    this.onCancel();
  }

  // calcPaFeatures() {
  //   const featureSet = new Set<Feature>();
  //   this.program.programProductTypeConfigs.forEach((p) => {
  //     p.productTypeConfig.pricedProductType.productType.productTypeFeatures.forEach(
  //       (q) => {
  //         featureSet.add(q.feature);
  //       }
  //     );
  //   });
  //   const paFeatures = Array.from(featureSet);
  //   return paFeatures;
  // }

  // calcPaAddons() {
  //   const addonSet = new Set<Addon>();
  //   this.program.programProductTypeConfigs.forEach((p) => {
  //     p.productTypeConfig.productTypeConfigAddons.forEach((q) => {
  //       addonSet.add(q.pricedAddon.addon)
  //     })
  //   });
  //   const paAddons = Array.from(addonSet);
  //   return paAddons;
  // }

  // ---------- Save and Undo --------------------------------

  override async addCrossValidationErrors() {
    if (this.program.programTypeId != ProgramTypeEnum.Managed) {
      this.createValidationError(this.program, 'programTypeId', 'Only Managed Programs are supported at this time.');
    }

    // TODO: check for dup budgets.
    const ok = await this.dbQueryService.checkIfIsUnique(this.program, 'id', 'accountId', 'name');

    if (!ok) {
      this.createValidationError(this.program, 'name', 'This account already has a program with this name.');
    }

    EntityFns.checkForDupErrors(
      this.program.programViews,
      (pi) => pi.name?.toLowerCase(),
      (e, dupName) => this.createValidationError(e, 'name', `Duplicate Program View Name: '${dupName}'`)
    );

    EntityFns.checkForDupErrors(
      this.program.programIssuances,
      (pi) => pi.name?.toLowerCase(),
      (e, dupName) => this.createValidationError(e, 'name', `Duplicate Program Issuance Name: '${dupName}'`)
    );

    EntityFns.checkForDupErrors(
      this.program.programAllowances,
      (pa) => pa.name?.toLowerCase(),
      (e, dupName) => this.createValidationError(e, 'name', `Duplicate Program Allowance Name: '${dupName}'`)
    );

    EntityFns.checkForDupErrors(
      this.program.programBudgets,
      (x) => x.budgetProductTypeTag?.name || '- Any -',
      (e, dupName) => this.createValidationError(e, 'name', `Duplicate Budget Product Tag Name: '${dupName}'`)
    );

    EntityFns.checkForDupErrors(
      this.program.programRapidTemplates,
      (x) => x.name?.toLowerCase(),
      (e, dupName) => this.createValidationError(e, 'name', `Duplicate Rapid Order Name: '${dupName}'`)
    );

    // Check if program has expired
    if (this.program.programStartDateTypeId == ProgramStartDateTypeEnum.CalendarDate) {
      if (this.program.startDate == null) {
        this.createValidationError(
          this.program,
          'startDate',
          'The Proximity Program start date is required when the Proximity Program start date type is calendar-based.'
        );
      } else {
        const expirationDate = this.program.endDate;
        if (expirationDate == null) {
          this.createValidationError(
            this.program,
            'endDate',
            'The Proximity Program end date is required when the Proximity Program start date type is calendar-based.'
          );
        }
        if (expirationDate && new Date().getTime() > expirationDate.getTime() && this.program.activeStatusId === ActiveStatusEnum.Active) {
          this.createValidationError(this.program, 'term', 'This Proximity Program has expired and may not be set to Active.');
        }
      }
    }

    if (this.program.programStartDateTypeId == ProgramStartDateTypeEnum.AnniversaryDate) {
      const startDays = this.program.startAfterAnniversaryNumDays;
      const endDays = this.program.endsAfterAnniversaryNumDays;
      if (startDays == null || endDays == null || startDays >= endDays) {
        this.createValidationError(
          this.program,
          null,
          'Anniversary programs require a start number of days to be greater than 0 and less than end number of days.'
        );
      }
    }

    if (this.program.isPurchaseOrderRequired && this.program.programPurchaseOrderTypeId == ProgramPurchaseOrderTypeEnum.None) {
      this.createValidationError(
        this.program,
        null,
        "A Purchase Order Type is required when 'A Purchase Order is required for every order.'"
      );
    }

    if (this.program.programPurchaseOrderTypeId === ProgramPurchaseOrderTypeEnum.ByProgram && !this.program.accountBlanketPurchaseOrderId) {
      this.createValidationError(this.program, null, 'A Program purchase order must be selected.');
    }

    if (this.program.isPcardRequired && this.program.programPcardTypeId == ProgramPcardTypeEnum.None) {
      this.createValidationError(this.program, null, "A P-card Type is required when 'A procurement card is required for every order'.");
    }

    if (this.program.programPcardTypeId === ProgramPcardTypeEnum.ByProgram && !this.program.accountProcurementCardId) {
      this.createValidationError(this.program, null, 'A Program p-card must be selected');
    }

    if (this.isActivating || this.program.activeStatusId == ActiveStatusEnum.Active) {
      let err = false;
      const expirationDate = this.program.endDate;
      if (
        this.program.programStartDateTypeId === ProgramStartDateTypeEnum.CalendarDate &&
        expirationDate &&
        new Date().getTime() > expirationDate.getTime()
      ) {
        this.createValidationError(this.program, null, 'An expired Proximity Program may not be activated');
        err = true;
      }
      // All users in an anniversary programs must have a program anniversary date
      if (this.program.programStartDateTypeId == ProgramStartDateTypeEnum.AnniversaryDate) {
        let err = false;
        const pugs = this.program.approvalTree?.approvalTreeUserGroups.map((x) => x.programUserGroup) ?? [];
        const exclusions = this.program.programUserGroupExclusions;
        pugs.forEach((p) => {
          p.programUserGroupMaps.forEach((q) => {
            const exclusion = exclusions.find((x) => x.accountUserId == q.accountUserId && x.programId == this.program.id);
            if (exclusion == undefined && !q.accountUser.programAnniversaryDate && !err) {
              this.createValidationError(
                this.program,
                null,
                "Every INCLUDED Account User associated with this 'Anniversary Date Program' must have a Program Anniversary Start Date."
              );
              err = true;
            }
          });
        });
      }

      if (
        this.program.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.NewUser ||
        this.program.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.Both
      ) {
        let err = false;
        const pugs = this.program.approvalTree?.approvalTreeUserGroups.map((x) => x.programUserGroup) ?? [];
        const exclusions = this.program.programUserGroupExclusions;
        pugs.forEach((p) => {
          p.programUserGroupMaps.forEach((q) => {
            const exclusion = exclusions.find((x) => x.accountUserId == q.accountUserId && x.programId == this.program.id);
            if (exclusion == undefined && !q.accountUser.newHireUntilDate && !err) {
              this.createValidationError(
                this.program,
                null,
                "Every INCLUDED Account User associated with this 'For New Users Program' must have a New Hire End Date."
              );
              err = true;
            }
          });
        });
      }

      // edited by Jeff 12/31/2024
      if (
        this.program.programPurchaseOrderTypeId === ProgramPurchaseOrderTypeEnum.ByShippingUserGroup &&
        this.program.isPurchaseOrderRequired
      ) {
        const sug = await this.dbQueryService.getShippingUserGroups(this.accountId, true);
        sug.some((p) => {
          if (p.activeStatusId === ActiveStatusEnum.Active && !p.accountBlanketPurchaseOrderId) {
            this.createValidationError(this.program, null, 'Every active Shipping User Group must have an assigned purchase order.');
            return true;
          } else {
            return false;
          }
        });
      }

      if (
        this.program.programPurchaseOrderTypeId === ProgramPurchaseOrderTypeEnum.ByProgramUserGroup &&
        this.program.isPurchaseOrderRequired
      ) {
        const pugs = this.program.approvalTree?.approvalTreeUserGroups.map((x) => x.programUserGroup) ?? [];
        pugs.some((p) => {
          if (p.activeStatusId === ActiveStatusEnum.Active && !p.accountBlanketPurchaseOrderId) {
            this.createValidationError(this.program, null, 'Every active Program User Group must have an assigned purchase order.');
            return true;
          } else {
            return false;
          }
        });
      }

      if (this.program.programPcardTypeId === ProgramPcardTypeEnum.ByShippingUserGroup && this.program.isPcardRequired) {
        const sug = await this.dbQueryService.getShippingUserGroups(this.accountId, true);
        sug.some((p) => {
          if (p.activeStatusId === ActiveStatusEnum.Active && !p.accountProcurementCardId) {
            this.createValidationError(this.program, null, 'Every active Shipping User Group must have an assigned p-card.');
            return true;
          } else {
            return false;
          }
        });
      }

      if (this.program.programPcardTypeId === ProgramPcardTypeEnum.ByProgramUserGroup && this.program.isPcardRequired) {
        const pugs = this.program.approvalTree?.approvalTreeUserGroups.map((x) => x.programUserGroup) ?? [];
        pugs.some((p) => {
          if (p.activeStatusId === ActiveStatusEnum.Active && !p.accountProcurementCardId) {
            this.createValidationError(this.program, null, 'Every active Program User Group must have an assigned p-card.');
            return true;
          } else {
            return false;
          }
        });
      }
      // edit end

      /*       let hasProgramUserGroups = false;
      this.account?.approvalTrees.forEach((p) => {
        if (this.approvalTreeHasProgramUserGroups(p)) {
          hasProgramUserGroups = true;
        }
      })

      if (!hasProgramUserGroups) {
        this.createValidationError(this.program, null, 'At least one Program User Group must exist within the Proximity Organization.')
      } */

      if (!this.program.approvalTreeId) {
        this.createValidationError(this.program, 'approvalTreeId', 'A Proximity Organization Tree is required.');
      }

      if (this.program.getProgramUserGroups().length == 0) {
        this.createValidationError(
          this.program,
          'programUserGroups',
          'Selected Proximity Organization Tree must have least one Program User Group.'
        );
      }

      if (this.program.billingAccountAddressId == null) {
        this.createValidationError(this.program, 'billingAccountAddress', 'A Billing Address is required.');
      }

      this.program.programAllowances.forEach((pa) => {
        if (pa.programAllowanceUserGroupMaps.length == 0) {
          // Note: this is an unusual case - in order for cell validation errors to appear
          // they must be on a modified or added entity.  In this case there is no programAllowanceUserGroup left
          // to create a validation error on - so we need to do it to the parent programAllowance but it
          // may be unmodified - so in this one case we force a modified state on an object that was not actually changed

          // if (pa.entityAspect.entityState.isUnchanged()) {
          //   pa.entityAspect.setModified();
          // }
          this.createValidationError(
            pa,
            null,
            `
            At least one user group must be selected for each allowance.`
          );
        }
      });

      this.program.programIssuances.forEach((iss) => {
        if (iss.programIssuanceUserGroupMaps.length == 0) {
          this.createValidationError(
            iss,
            null,
            `
            At least one user group must be selected for each issuance.`
          );
        }
      });

      this.program.programAccountIssuanceMaps
        .filter((x) => x.programId == this.program.id)
        .forEach((paim) => {
          const iss = paim.accountIssuance;
          if (iss.accountIssuanceUserGroupMaps.length == 0) {
            this.createValidationError(iss, null, `At least one user group must be selected for each included account issuance.`);
          }
        });

      if (this.program.programAccountIssuanceMaps.length > 0 && this.program.programIssuances.length > 0) {
        this.program.programIssuances.forEach((pi) => {
          if (
            this.program.programAccountIssuanceMaps.find((x) => x.accountIssuance.programProductTypeTagId === pi.programProductTypeTagId)
          ) {
            this.createValidationError(
              this.program,
              null,
              `The product type '" + pi.programProductTypeTag.name + "' may not be shared between a program and an account issuance limit.`
            );
          }
        });
      }

      if (this.program.programAllowances.length > 0) {
        this.program.programAllowances.forEach((pa) => {
          if (pa.allowanceAmt < 0) {
            this.createValidationError(
              this.program,
              null,
              "Allowance amount for '" + pa.name + "' must be greater than or equal to $0.00."
            );
          }

          if (pa.allowanceAmt == 0.0) {
            if (!pa.allowOverAllowancePurchases || !pa.isFreightChargedToAllowance || pa.programAllowanceFeatureExceptions.length > 0) {
              this.createValidationError(
                this.program,
                null,
                pa.name +
                  ` has no allowance amount and therefore has the following requirements: (1) over-purchase must be enabled (2) freight must be charged to the allowance and (3) may have no excluded feature charges.`
              );
            }
          }
        });
      } else {
        const badPis = this.getInvalidProgramIssuances();
        if (badPis.length > 0) {
          badPis.forEach((pi) => {
            this.createValidationError(pi, null, `Not every user group was selected for the product type specified by this issuance.`);
          });
        }

        if (this.program.programIssuances.length > 0 || this.program.programAccountIssuanceMaps.length > 0) {
          if (this.program.programProductTypeConfigs.some((x) => x.productTypeConfig.pricedProductType.productTags == '')) {
            this.createValidationError(
              this.program,
              null,
              `This program has no Allowances and contains Products that do not have a Product Type Tag. 
               Ensure that every Product has a Tag or add an allowance to fix the issue.`
            );
          } else {
            // stopped above but still needed here because logic requires all issuances to have tags.
            if (
              this.program.programIssuances.every((x) => x.programProductTypeTag != null) &&
              this.program.programAccountIssuanceMaps.map((x) => x.accountIssuance.programProductTypeTag != null)
            ) {
              const piTags = this.program.programIssuances.map((x) => x.programProductTypeTag);

              const aiTags = this.program.programAccountIssuanceMaps.map((x) => x.accountIssuance.programProductTypeTag);
              const issuanceTagMap = new Map([...piTags, ...aiTags].map((x) => [x.id, x]));

              const pricedProductTypes = this.program.programProductTypeConfigs.map((x) => x.productTypeConfig.pricedProductType);
              const productTags = _.flatMap(pricedProductTypes, (x) => x.programProductTypeTagMaps.map((y) => y.programProductTypeTag));
              const productTagMap = new Map(productTags.map((x) => [x.id, x]));
              const missingTagMap = new Map(productTagMap);
              for (const id of issuanceTagMap.keys()) {
                missingTagMap.delete(id);
              }
              if (missingTagMap.size > 0) {
                const missingTagNames = Array.from(missingTagMap.values())
                  .map((x) => x.name)
                  .join(', ');
                this.createValidationError(
                  this.program,
                  null,
                  `This program has no Allowances and their are no issuances for the following ${missingTagMap.size} product tag(s)
                  : ${missingTagNames}.
                  This is required for ACTIVE Programs.`
                );
              }
            }
          }
        }
      }

      // If no programIssuances then every ProgramUserGroup must be associated with an Allowance
      if (this.program.programIssuances.length == 0 && this.program.programAccountIssuanceMaps.length == 0) {
        const pugsWithoutAllowances = this.getProgramUserGroupsWithoutAllowances();
        if (pugsWithoutAllowances.length > 0) {
          const pugsWithoutAllowanceNames = pugsWithoutAllowances.map((x) => x.name).join(', ');
          this.createValidationError(
            this.program,
            null,
            `This program has no Issuances and the following ${pugsWithoutAllowances.length} Program User Groups do not have an Allowance
            : ${pugsWithoutAllowanceNames}.
            This is required for ACTIVE Programs.`
          );
        }
      }
    }
  }

  approvalTreeHasProgramUserGroups(tree: ApprovalTree) {
    let result = false;
    tree.approvalTreeAdminGroups.forEach((p) => {
      if (p.approvalTreeUserGroups.length > 0) {
        result = true;
      }
    });
    return result;
  }

  getProgramUserGroupsWithoutAllowances() {
    const allowances = this.program.programAllowances;
    const pugs = this.program.approvalTree.approvalTreeUserGroups.map((x) => x.programUserGroup);
    const pugsWithAllowances = _.flatten(allowances.map((x) => x.programAllowanceUserGroupMaps.map((y) => y.programUserGroup)));
    const pugsWithAllowancesSet = new Set(pugsWithAllowances);
    const pugsWithoutAllowances = pugs.filter((x) => !pugsWithAllowancesSet.has(x));
    return pugsWithoutAllowances;
  }

  getInvalidProgramIssuances() {
    const pIssuances = this.program.programIssuances;
    const pugs = this.program.approvalTree.approvalTreeUserGroups.map((x) => x.programUserGroup);
    const invalidPIssuances = pIssuances.filter((iss) => {
      const usedPugIds = new Set(
        _.flatten(
          pIssuances
            // all issuances with same tag as this one
            .filter((x) => x.programProductTypeTagId == iss.programProductTypeTagId)
            .map((x) => x.programIssuanceUserGroupMaps.map((x) => x.programUserGroupId))
        )
      );
      const availablePugs = pugs.filter((x) => !usedPugIds.has(x.id));
      return availablePugs.length > 0;
    });
    return invalidPIssuances;
  }

  // for now called from ProgramSuppliers page.
  public async createMissingProgramSuppliers() {
    const suppliers = _.uniq(this.program.programProductTypeConfigs.map((x) => x.productTypeConfig.pricedProductType.productType.supplier));
    const missingSuppliers = suppliers.filter((x) => !this.program.programSuppliers.some((y) => y.supplierId == x.id));
    const newProgramSuppliers = missingSuppliers.map((x) => {
      return this.dbSaveService.createEntity(ProgramSupplier, {
        id: Guid.create().toString(),
        programId: this.program.id,
        supplierId: x.id,
        doesAccountPayFreight: true,
        shouldChargeFreightOnFirstShipmentOnly: false,
        handlingChargePerOrderAmt: 0,
        handlingChargePerShipmentAmt: 0,
      });
    });
    return this.program.programSuppliers;
    // if (newProgramSuppliers.length > 0) {
    //   await this.dbSaveService.saveSelectedChanges(newProgramSuppliers);
    // }
  }

  override async beforeSave() {
    if (this.isBeingAdded) {
      this.program.entityAspect.setAdded();
    }
    this.createMissingProgramSuppliers();
    return true;
  }

  override async afterSave() {
    this.isBeingAdded = false;
    if (this.isActivating) {
      this.program.activeStatusId = ActiveStatusEnum.Active;
      await this.dbSaveService.saveChanges();
    }
    this.refreshAllSubs();
  }

  override async afterUndo() {
    // Forcing a reload of the component is simplest way to avoid side effects.
    // browser reload - works

    // below doesn't work after saving a new program
    //window.location.reload();

    this.router.navigate(['account/accounts', this.accountId, 'manage', 'programs', this.program.id]).then(() => {
      window.location.reload();
    });
  }

  override navigateToValidationError(ee: EntityError) {
    const errEnt = ee.entity;
    const propName = ee.propertyName;

    let viewEnum = ViewEnum.None;

    if (errEnt instanceof ProgramProductTypeConfig) {
      viewEnum = ViewEnum.ProductTypeConfigs;
    } else if (errEnt instanceof ProgramProductTypeTag || errEnt instanceof ProgramProductCategoryTag) {
      viewEnum = ViewEnum.ProductTags;
    } else if (errEnt instanceof ProgramView) {
      viewEnum = ViewEnum.ProductViews;
    } else if (errEnt instanceof ProgramRapidTemplate) {
      viewEnum = ViewEnum.RapidTemplates;
    } else if (errEnt instanceof ProgramIssuance) {
      viewEnum = ViewEnum.Issuances;
    } else if (errEnt instanceof ProgramAllowance) {
      viewEnum = ViewEnum.Allowances;
    } else if (errEnt instanceof ProgramBudget) {
      viewEnum = ViewEnum.Budgets;
    } else if (errEnt instanceof Program) {
      if (propName == 'billingAccountAddressId') {
        viewEnum = ViewEnum.Billing;
      } else {
        viewEnum = ViewEnum.Info;
      }
    }

    if (viewEnum != ViewEnum.None) {
      this.navigateToErrorAsync(viewEnum, errEnt, propName);
    } else {
      UtilFns.focusInputByEntity('#topLevel', errEnt, propName);
    }
  }

  async navigateToErrorAsync(viewEnum: ViewEnum, errEnt: Entity, propName: string) {
    this.activeView = viewEnum;
    await UtilFns.wait(1);
    const viewName = ViewEnum[viewEnum];
    const activeComp = this.componentNameMap[viewName];
    const fn = activeComp?.markError?.bind(activeComp);
    if (fn) {
      fn(errEnt, propName);
    }
  }

  onGotoApprovalTree(approvalTree: ApprovalTree) {
    if (!approvalTree) {
      this.toastr.warning('Select a Proximity Account Organzation Tree first', ' Unable to Proceed');
      return;
    }

    this.router.navigate(['account/accounts', this.accountId, 'manage', 'approval-trees'], { queryParams: { key: approvalTree.id } });
  }

  onGotoBlanketPo(blanketPoId?: string) {
    // this.router.navigate(['account/accounts', this.accountId, 'manage', 'approval-trees'], { queryParams: { key: approvalTree.id } });
  }

  onGotoUserGroup(aug: ProgramUserGroup) {
    this.router.navigate(['account/accounts', this.accountId, 'manage', 'program-user-groups'], { queryParams: { key: aug.id } });
  }

  /*   statusMessage(): string {
    if (this.isBeingAdded) return "";
    return <string>this.statusService.getWorkingStatus(this.program as any).longDisplay;
  } */

  /*   public isReadOnly(): boolean {
    return (this.statusService.getWorkingStatus(this.program as any).isReadOnly || (this.authCanEdit && !this.canEdit));
  } */

  /*   public isActive() {
    return this.statusService.getWorkingStatus(this.program as any).maxStatusId == 1;
  } */
}
