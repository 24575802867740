// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { AccountAddress } from './account-address';
import { AccountBlanketPurchaseOrder } from './account-blanket-purchase-order';
import { AccountProcurementCard } from './account-procurement-card';
import { ActiveStatus } from './active-status';
import { ApprovalTree } from './approval-tree';
import { JobOrder } from './job-order';
import { Manifest } from './manifest';
import { ProgramAccountIssuanceMap } from './program-account-issuance-map';
import { ProgramAllowance } from './program-allowance';
import { ProgramAllowanceRemainderRule } from './program-allowance-remainder-rule';
import { ProgramApplicabilityType } from './program-applicability-type';
import { ProgramBudget } from './program-budget';
import { ProgramIssuance } from './program-issuance';
import { ProgramPcardType } from './program-pcard-type';
import { ProgramProductCategoryTag } from './program-product-category-tag';
import { ProgramProductTypeConfig } from './program-product-type-config';
import { ProgramPurchaseOrderType } from './program-purchase-order-type';
import { ProgramRapidTemplate } from './program-rapid-template';
import { ProgramStartDateType } from './program-start-date-type';
import { ProgramSupplier } from './program-supplier';
import { ProgramType } from './program-type';
import { ProgramUserGroupExclusion } from './program-user-group-exclusion';
import { ProgramView } from './program-view';
import { ProgramViolationRule } from './program-violation-rule';
import { ShipFrequency } from './ship-frequency';
import { ActiveStatusEnum } from './enums';
import { ShipFrequencyEnum } from './enums';
import { ProgramViolationRuleEnum } from './enums';
import { ProgramAllowanceRemainderRuleEnum } from './enums';
import { ProgramApplicabilityTypeEnum } from './enums';
import { ProgramPcardTypeEnum } from './enums';
import { ProgramPurchaseOrderTypeEnum } from './enums';
import { ProgramStartDateTypeEnum } from './enums';
import { ProgramTypeEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags
import { EntityPropertyAnnotation, EntityTypeAnnotation } from '../entity-type-annotations';
import { DateFns, UtilFns } from '@utils';
import { AccountUser } from './account-user';
import { ProxAgFns } from '@core';
/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class Program extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  // static getEntityTypeAnnotation(): EntityTypeAnnotation {
    
  //   const propAnnotations = [
  //     // TODO: need a validator that requires a start and end date based on the programStartDateType
  //   ];

  //   return new EntityTypeAnnotation({
  //     validators: [  ],
  //     propertyAnnotations: propAnnotations
  //   });
        
  // }

  // used as a temporary storage area when building new jobOrders. 
  _previousProgramId!: string;

  hasStatusChanged() {
    const ea = this.entityAspect;
    if (!ea) return false
    if (ea.entityState.isUnchanged() ) return false;
    return this.activeStatusId !=  this.getOriginalStatusId();
  }

  getOriginalStatusId() {
    return this.entityAspect?.originalValues[this.activeStatusId] ?? this.activeStatusId;
  }

  isUserApplicable(au: AccountUser): string | null {
    if (this.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.Both) {
      return null;
    } else if (this.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.NewUser) {
      return au.isNewUser() ? null : 'This order does not qualify for a New User Proximity Program';
    } else if (this.programApplicabilityTypeId == ProgramApplicabilityTypeEnum.ExistingUser) {
      return !au.isNewUser() ? null : 'This order does not qualify for an Existing User Proximity Program';
    } else {
      UtilFns.assert(false, 'Invalid Program Applicability Type');
    }
  }

  // Jay: is this code duplicated elsewhere?
  isOpen(au: AccountUser) {
    if (this.programStartDateTypeId === ProgramStartDateTypeEnum.CalendarDate) {
      return (DateFns.today() <= <Date>this.endDate)
    }

    if (this.programStartDateTypeId === ProgramStartDateTypeEnum.AnniversaryDate) {
      if (!au.hireDate || !this.startAfterAnniversaryNumDays || !this.endsAfterAnniversaryNumDays) return false;
      const startDate = DateFns.dateAdd(au.hireDate, this.startAfterAnniversaryNumDays, 'days');
      const endDate = DateFns.dateAdd(au.hireDate, this.endsAfterAnniversaryNumDays, 'days');
      return (startDate <= DateFns.today() && endDate >= DateFns.today());
    }

    return false;
  }

  getStatusParents() {
    return [this.account];
  }

  getStatusId() {
    return this.activeStatusId;
  }

  getProgramUserGroups() {
    return this.approvalTree?.approvalTreeUserGroups.map((x) => x.programUserGroup) ?? [];
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid */
  accountAdminGroupId?: string;
  /** Guid FK accountBlanketPurchaseOrder */
  accountBlanketPurchaseOrderId?: string;
  /** Guid FK account */
  accountId!: string;
  /** Guid FK accountProcurementCard */
  accountProcurementCardId?: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** Enum FK allowanceProgramViolationRule */
  allowanceProgramViolationRuleId!: ProgramViolationRuleEnum;
  /** Guid FK approvalTree */
  approvalTreeId?: string;
  /** Guid FK billingAccountAddress */
  billingAccountAddressId?: string;
  /** Boolean */
  canShipHome!: boolean;
  /** DateTime */
  cancellationDate?: Date;
  /** String */
  description?: string;
  /** Boolean */
  doAllOrdersRequireApproval!: boolean;
  /** DateTime */
  endDate?: Date;
  /** Int32 */
  endsAfterAnniversaryNumDays?: number;
  /** Boolean */
  isPcardRequired!: boolean;
  /** Boolean */
  isPurchaseOrderRequired!: boolean;
  /** Enum FK issuanceProgramViolationRule */
  issuanceProgramViolationRuleId!: ProgramViolationRuleEnum;
  /** Int32 */
  leadTimeToShipNumDays!: number;
  /** String */
  name!: string;
  /** Enum FK programAllowanceRemainderRule */
  programAllowanceRemainderRuleId!: ProgramAllowanceRemainderRuleEnum;
  /** Enum FK programApplicabilityType */
  programApplicabilityTypeId!: ProgramApplicabilityTypeEnum;
  /** Enum FK programPcardType */
  programPcardTypeId!: ProgramPcardTypeEnum;
  /** Enum FK programPurchaseOrderType */
  programPurchaseOrderTypeId!: ProgramPurchaseOrderTypeEnum;
  /** Enum FK programStartDateType */
  programStartDateTypeId!: ProgramStartDateTypeEnum;
  /** Enum FK programType */
  programTypeId!: ProgramTypeEnum;
  /** Binary */
  rowVersion!: any;
  /** Enum FK shipFrequency */
  shipFrequencyId!: ShipFrequencyEnum;
  /** Int32 */
  startAfterAnniversaryNumDays?: number;
  /** DateTime */
  startDate?: Date;
  /** FK accountId */
  account!: Account;
  /** FK accountBlanketPurchaseOrderId */
  accountBlanketPurchaseOrder!: AccountBlanketPurchaseOrder;
  /** FK accountProcurementCardId */
  accountProcurementCard!: AccountProcurementCard;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** FK allowanceProgramViolationRuleId */
  allowanceProgramViolationRule!: ProgramViolationRule;
  /** FK approvalTreeId */
  approvalTree!: ApprovalTree;
  /** FK billingAccountAddressId */
  billingAccountAddress!: AccountAddress;
  /** FK issuanceProgramViolationRuleId */
  issuanceProgramViolationRule!: ProgramViolationRule;
  /** Inv FK programId */
  jobOrders!: JobOrder[];
  /** Inv FK programId */
  manifests!: Manifest[];
  /** Inv FK programId */
  programAccountIssuanceMaps!: ProgramAccountIssuanceMap[];
  /** FK programAllowanceRemainderRuleId */
  programAllowanceRemainderRule!: ProgramAllowanceRemainderRule;
  /** Inv FK programId */
  programAllowances!: ProgramAllowance[];
  /** FK programApplicabilityTypeId */
  programApplicabilityType!: ProgramApplicabilityType;
  /** Inv FK programId */
  programBudgets!: ProgramBudget[];
  /** Inv FK programId */
  programIssuances!: ProgramIssuance[];
  /** FK programPcardTypeId */
  programPcardType!: ProgramPcardType;
  /** Inv FK programId */
  programProductCategoryTags!: ProgramProductCategoryTag[];
  /** Inv FK programId */
  programProductTypeConfigs!: ProgramProductTypeConfig[];
  /** FK programPurchaseOrderTypeId */
  programPurchaseOrderType!: ProgramPurchaseOrderType;
  /** Inv FK programId */
  programRapidTemplates!: ProgramRapidTemplate[];
  /** FK programStartDateTypeId */
  programStartDateType!: ProgramStartDateType;
  /** Inv FK programId */
  programSuppliers!: ProgramSupplier[];
  /** FK programTypeId */
  programType!: ProgramType;
  /** Inv FK programId */
  programUserGroupExclusions!: ProgramUserGroupExclusion[];
  /** Inv FK programId */
  programViews!: ProgramView[];
  /** FK shipFrequencyId */
  shipFrequency!: ShipFrequency;
}

