<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [showBack]="true"></prox-main>

<ng-template #bodyTemplate>
  <p-confirmDialog></p-confirmDialog>
  <h4 *ngIf='this.accountId == null' class="card-header">
    <span>Notification Submission Status {{ this.accountId == null ? ' - Global' : ''   }}</span>
  </h4>
  <div class="flex flex-column h-full">
    <div class="flex mb-2">
      <label class="mt-2">Show submissions after</label>
      <p-calendar class="ml-2" [(ngModel)]="afterDate" (ngModelChange)="refresh()" ></p-calendar>
      <p-checkbox class="ml-4" [(ngModel)]="shouldShowErrorsOnly" [binary]="true" (ngModelChange)="refresh()" label="Show Errors Only" ></p-checkbox>
      <button class="ml-4 p-button-sm" [disabled]="isBusy()" (click)="submitAllPending()" pButton type="button" label="Submit All Pending"  ></button>
      <button class="ml-4 p-button-sm" [disabled]="isBusy()" (click)="refresh()" pButton type="button" label="Refresh"  ></button>
      <p-progressSpinner class="ml-4" *ngIf="isBusy()" ariaLabel="loading" [style]="{ width: '30px', height: '30px' }" />
    </div>

    <div class="flex-grow-1 mt-2">
      <prox-ag-grid [gridOptions]="nsGridOptions" [rowData]="notificationSubmissions"></prox-ag-grid>
      
    </div>
  </div>

</ng-template>