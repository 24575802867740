<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>

<ng-template #bodyTemplate>
  <prox-account-activation-checklist *ngIf="this.isValidateActivated"
    [activationErrors]="this.activationService.activateErrors"
    [accountName]="account.name"></prox-account-activation-checklist>
  <h5 *ngIf="!this.isValidateActivated" class="card-header">
    <div class="flex flex-row">
      <span *ngIf="!isBeingAdded">{{account.name}}</span>
      <span *ngIf="isBeingAdded">* New Account *</span>
      <span *ngIf="!isBeingAdded"
        style="margin-left:10px; padding-top: 4px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
    </div>
  </h5>
  <div class="flex flex-row gap-6">
    <div class="card-body flex-grow-2 flex flex-column w-6" *ngIf="isPageReady">
      <fieldset style=" border-width: 0px; margin-left: -10px;">
        <!--       <div class="flex flex-column"> -->
        <div class="w-12 pb-3">
          <prox-field label="Account Name">
            <input type="text" [disabled]="isReadOnly()" pInputText autocomplete="off" pAutoFocus [autofocus]="true"
              [(ngModel)]="account.name" [bzModel]="account" name="name" />
          </prox-field>
        </div>
        <div class="flex flex-row">
          <div class="w-6 pb-3">
            <prox-field label="Start Date">
              <p-calendar [disabled]="!isBeingAdded" [(ngModel)]="account.startDate" [bzModel]="account"
                name="startDate"></p-calendar>
            </prox-field>
          </div>

          <div class="w-6 pl-3">
            <prox-field label="Fiscal Start Month">
              <p-dropdown [options]="fiscalMonthNums" optionLabel="label" optionValue="value"
                [(ngModel)]="fiscalMonthNum" (ngModelChange)="fiscalDateChanged()" name="fiscalMonthNum"
                [disabled]="isReadOnly()"></p-dropdown>
            </prox-field>
          </div>
          <div class="w-6 pl-3">
            <prox-field label="Fiscal Start Day">
              <p-dropdown [options]="fiscalDayNums" optionLabel="label" optionValue="value" [(ngModel)]="fiscalDayNum"
                (ngModelChange)="fiscalDateChanged()" name="fiscalDayNum" [disabled]="isReadOnly()"></p-dropdown>
            </prox-field>
          </div>
        </div>
        <!--         <div class="flex flex-row mb-4">
          <div class="w-12">
            <label>Current Year Fiscal Period: {{ currentFiscalPeriod }}</label>
          </div>
        </div> -->
        <div class="w-12">
          <prox-field label="User Import Code Requirement"></prox-field>
          <p-dropdown [options]="importOptions" [(ngModel)]="account.accountImportCodeOptionId" optionValue="id"
            [disabled]="isReadOnly()" optionLabel="name" placeholder="Choose Account User import requirement"
            name="importOptions"></p-dropdown>
        </div>
        <div class="mt-3">
          <div *ngIf="(!isBeingAdded)">
            <prox-field label="Principal Account Administrator"></prox-field>
            <p-dropdown [options]="filterAccountAdmins" [(ngModel)]="account.primaryAccountAdminId" optionValue="id"
              [disabled]="isReadOnly()" optionLabel="proximityUser.name"
              placeholder="Select your Principal Account Administrator" name="accountAdmins"></p-dropdown>
            <span style="padding-left: 10px;">
              <button (click)="gotoAdministrator()" pButton type="button" label="View" class="p-button-sm"
                [disabled]="disableViewAdministrator()"></button>
            </span>
          </div>
        </div>
        <div class="flex mt-6">
          <div class="w-12" *ngIf="isBeingAdded">
            <div class="flex">
              <h5>Principal Account Administrator</h5>
            </div>
            <prox-user-frm [proximityUser]="proximityUser" [isBeingAdded]="isBeingAdded"
              [roles]="allRoles"></prox-user-frm>
          </div>
        </div>
      </fieldset>
    </div>
    <div *ngIf="!isBeingAdded" class="flex flex-column w-3 mt-5">
      <div>
        <p-panel header="Current Status">
          <h3>{{account.activeStatus.name}}</h3>
          <p-button size="small" [disabled]="isValidateActivated" (click)="toggleActivate()"
            label={{statusButtonLabel()}}></p-button>
        </p-panel>
      </div>
      <div class="mt-3">
        <p-panel header="Current Fiscal Period">
          <h4>{{ currentFiscalPeriod }}</h4>
        </p-panel>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" (undo)="onCancel()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>